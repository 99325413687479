const parseISORaw = require('date-fns/parseISO')

// Hacks for using in node and browser
const parseISO =
  typeof parseISORaw === 'object' ? parseISORaw.default : parseISORaw

const parse = (date_time) =>
  typeof date_time === 'string' ? parseISO(date_time) : date_time

module.exports = {
  parse: parse,
}
